import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import UserFetcher from "./UserFetcher";
import ExportExcel from "./ExportExcel";
import DataFetcher from "./dataFetcher";

function AdminMenu({ user }) {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvPreview, setCsvPreview] = useState([]);
  const [isFetcher, setIsFetcher] = useState(false);
  const [rfidNumber, setRfidNumber] = useState("");
  const [fetchData, setFetchData] = useState(false);
  const fileInputRef = useRef();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToEdit, setUserToEdit] = useState(null);
  const [showDepoInput, setShowDepoInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [depoOptions, setDepoOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userData, setUserData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  // Function to fetch users from API
  useEffect(() => {
    fetch("https://rfidpartnership.com/api/users")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data.users);
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  const handleUserChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedUser(selectedValue);
    console.log("Selected user", selectedUser);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Read the CSV file and update preview state
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n").map((row) => row.split(","));
      setCsvPreview(rows);
    };
    reader.readAsText(file);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setCsvPreview([]);
    fileInputRef.current.value = "";
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch("https://rfidpartnership.com/api/upload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("File uploaded successfully");
        setFetchData(true); // Trigger fetching data
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [userFormData, setUserFormData] = useState({
    user_id: "",
    username: "",
    password: "",
    branch_name: "",
    type: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    depo_id: "",
  });

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);

    setUserFormData((prevFormData) => ({
      ...prevFormData,
      depo_id: selectedValue,
    }));
    console.log("User form data:", userFormData);

    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    fetchUserData();

    axios
      .get("https://rfidpartnership.com/api/depo")
      .then((response) => {
        setDepoOptions(
          response.data.documents.map((doc) => ({
            user_id: doc.user_id,
            username: doc.username,
            branch_name: doc.branch_name,
            depo_id: doc.depo_id,
          }))
        );
      })
      .catch((err) => console.error(err));
  }, []);

  const renderCsvPreview = () => {
    if (csvPreview.length === 0) return null;

    // Extract headers and data rows
    const headers = csvPreview[0];
    const dataRows = csvPreview.slice(1);
    const previewRows = dataRows.slice(0, 5);
    const totalRows = dataRows.length;

    return (
      <div className="csv-preview">
        <h3>CSV Preview:</h3>
        <p>Total rows: {totalRows}</p>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {previewRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleManualEntrySubmit = async () => {
    if (!rfidNumber) return;

    try {
      const response = await fetch("https://rfidpartnership.com/api/manual-entry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rfid: rfidNumber }),
      });

      if (response.ok) {
        console.log("RFID number added successfully");
        setFetchData(true); // Trigger fetching data
      } else {
        console.error("Failed to add RFID number");
      }
    } catch (error) {
      console.error("Error adding RFID number:", error);
    }
  };

  const handleRetrieveData = (e) => {
    e.preventDefault();
    setFetchData(true); // Trigger fetching data
  };

  const fetchUserData = () => {
    fetch("https://rfidpartnership.com/api/users")
      .then((response) => response.json())
      .then((data) => setUserData(data.documents))
      .catch((err) => console.error(err));
  };

  const showModal = (modalType, user = null) => {
    if (modalType === "addUser") {
      setShowAddUserModal(true);
      setUserFormData({
        user_id: "",
        username: "",
        password: "",
        type: "",
        depo_id: "",
      });
    } else if (modalType === "editUser") {
      setShowEditUserModal(true);
      setUserToEdit(user);
      setUserFormData({
        user_id: user.user_id,
        username: user.username,
        branch_name: user.branch_name,
        password: user.password,
        type: user.type,
        depo_id: user.depo_id,
      });
    }
  };

  const closeModal = () => {
    setShowAddUserModal(false);
    setShowEditUserModal(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUserFormChange = (event) => {
    const { name, value } = event.target;
    setUserFormData({ ...userFormData, [name]: value });

    // Set showDepoInput to true if user type is "cabang"
    if (name === "type" && value === "cabang") {
      setShowDepoInput(true);
    } else {
      setShowDepoInput(false);
    }
  };

  const handleUserRowClick = (user) => {
    setUserToEdit(user);
    showModal("editUser", user);
  };

  const handleUserFormSubmit = (e) => {
    // e.preventDefault();
    if (showAddUserModal) {
      const requestBody = JSON.stringify({
        username: userFormData.username,
        password: userFormData.password,
        branch_name: userFormData.branch_name,
        type: userFormData.type,
        depo_id: userFormData.depo_id,
      });
      console.log(requestBody);

      fetch("https://rfidpartnership.com/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          fetchUserData(); // Refresh data after adding user
          closeModal();
        })
        .catch((error) => console.error("Error adding user:", error));
    } else if (showEditUserModal) {
      console.log(userFormData);
      // Edit user
      fetch(`https://rfidpartnership.com/api/users/${userToEdit.user_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userFormData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          fetchUserData();
          closeModal();
        })
        .catch((error) => console.error("Error editing user:", error));
    }
  };

  const handleDelete = (userId) => {
    if (window.confirm("Apakah anda yakin ingin menghapus user ini?")) {
      fetch(`https://rfidpartnership.com/api/users/${userId}`, {
        method: "DELETE",
      })
        .then((response) => {
          window.location.reload();
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
        })
        .catch((error) => {
          // Alert user of error
          console.error("Error deleting user:", error);
        });
    }
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Admin Menu</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>List Users</h3>
              <div className="header-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <i
                  className="bx bx-plus"
                  onClick={() => showModal("addUser")}
                ></i>
              </div>
            </div>
            <UserFetcher
              returnType="table"
              searchQuery={searchQuery}
              onRowClick={handleUserRowClick}
              handleUserDelete={handleDelete}
            />
          </div>
        </div>

        <div className="bottom-data" style={{ flexDirection: "column" }}>
          <div className="settings-view">
            <div className="header">
              <h3>Add New RFID</h3>
            </div>
            <form>
              <div className="user-dropdown" style={{ marginBottom: "16px" }}>
                <label htmlFor="userSelect">Select User to Assign:</label>
                <select
                  id="userSelect"
                  value={selectedUser}
                  onChange={handleUserChange}
                >
                  <option value="" selected disabled>
                    Select user...
                  </option>
                  {users.map((user) => (
                    <option classNamekey={user.user_id} value={user.user_id}>
                      {user.branch_name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="switch-button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsFetcher(!isFetcher);
                }}
              >
                {isFetcher ? "Switch to CSV Upload" : "Switch to Fetcher"}
              </button>

              {isFetcher ? (
                <div className="file-buttons">
                  <button
                    className="switch-button"
                    onClick={handleRetrieveData}
                  >
                    Retrieve Data
                  </button>
                  <span></span>
                  {fetchData && (
                    <DataFetcher
                      key={Date.now()}
                      returnType="upload"
                      selectedUser={selectedUser}
                      user={user}
                    />
                  )}
                </div>
              ) : (
                <div className="upload-csv-container">
                  <div className="file-input-container">
                    <div className="file-input">
                      <label htmlFor="csvFile">Upload CSV:</label>
                      <input
                        type="file"
                        id="csvFile"
                        name="csvFile"
                        accept=".csv"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                    </div>
                    {renderCsvPreview()}
                    <div className="file-buttons">
                      <button onClick={handleClearFile}>Clear</button>
                      <button
                        onClick={handleFileUpload}
                        disabled={!selectedFile}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </main>

      {/* Add User Modal */}
      <div className={`modal ${showAddUserModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Add User</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="addUser" onSubmit={handleUserFormSubmit}>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={handleUserFormChange}
                required
              />
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleUserFormChange}
                required
              />
              <label htmlFor="branch_name">Branch Name :</label>
              <input
                type="text"
                id="branch_name"
                name="branch_name"
                onChange={handleUserFormChange}
                required
              />
              <label htmlFor="type">Type:</label>
              <select
                id="type"
                name="type"
                onChange={handleUserFormChange}
                required
              >
                <option value="" disabled selected>
                  Select User Type
                </option>
                <option value="cabang">Cabang</option>
                <option value="depo">Depo</option>
              </select>

              {showDepoInput && (
                <select
                  id="depo_id"
                  name="depo_id"
                  className="search-bar uppercase"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="" disabled selected>
                    Select Depo
                  </option>
                  {depoOptions.map((option) => (
                    <option
                      key={option.user_id}
                      value={option.user_id}
                      className="uppercase"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              )}
              <button className="modal-button edit" type="submit">
                Add User
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Edit User Modal */}
      <div className={`modal ${showEditUserModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Edit User</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="editUser" onSubmit={handleUserFormSubmit}>
              <label htmlFor="username">Username :</label>
              <input
                type="text"
                id="username"
                name="username"
                value={userFormData.username}
                onChange={handleUserFormChange}
                required
              />
              <label htmlFor="password">Password :</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={userFormData.newPassword}
                onChange={handleUserFormChange}
                required
              />
              <label htmlFor="branch_name">Branch Name :</label>
              <input
                type="text"
                id="branch_name"
                name="branch_name"
                value={userFormData.branch_name}
                onChange={handleUserFormChange}
                required
              />
              <label htmlFor="type">Type : </label>
              <select
                id="type"
                name="type"
                // value={userFormData.type}
                onChange={handleUserFormChange}
                required
              >
                <option value="" disabled selected>
                  Select User Type
                </option>
                <option value="cabang">Cabang</option>
                <option value="depo">Depo</option>
              </select>
              {showDepoInput && (
                <select
                  id="depo_id"
                  name="depo_id"
                  className="search-bar uppercase"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="" disabled selected>
                    Pilih Depo
                  </option>
                  {depoOptions.map((option) => (
                    <option
                      key={option.user_id}
                      value={option.user_id}
                      className="uppercase"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              )}
              <button className="modal-button edit" type="submit">
                Edit User
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMenu;
