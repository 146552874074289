import React, { useState, useEffect } from "react";
import HistoryFetcher from "./HistoryFetcher";

function Approval({ user }) {
  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>History</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>History Transfer</h3>
              <div className="header-container"></div>
            </div>
            <div className="HistoryDisplay">
              <HistoryFetcher returnType="table" user={user} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Approval;
