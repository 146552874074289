import React, { useEffect,useState } from 'react';

const CombinedFetcher = ({ onFetchCombinedData, username, user_idP, refreshKey }) => {
  const [documentsData, setDocumentsData] = useState([]);
  const [rfidData, setRfidData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [error, setError] = useState(null);

  const fetchCombinedData = async () => {
    try {
      const response = await fetch(`https://rfidpartnership.com/api/documents/?username=${username}`);
      if (!response.ok) throw new Error('Waiting for andorid send data');
      const data = await response.json();
      setDocumentsData(data.documents);

      const rfidResponse = await fetch(`https://rfidpartnership.com/api/data/${user_idP}`);
      if (!rfidResponse.ok) throw new Error('Waiting for android send data');
      const rfidData = await rfidResponse.json();
      setRfidData(rfidData.data.split(','));
    } catch (err) {
      console.error('Error fetching combined data:', err);
      // setError('Error fetching data');
    }
  };

  useEffect(() => {
    // This effect runs every time the refreshKey changes
    fetchCombinedData();
  }, [refreshKey]);

  useEffect(() => {
    if (documentsData.length > 0 && rfidData.length > 0) {
      const combined = rfidData.map(rfid_tag_id => {
        const document = documentsData.find(doc => doc.rfid_tag_id === rfid_tag_id.trim());
        return {
          rfid_tag_id,
          nama_dokumen: document ? document.nama_dokumen : 'Unknown',
        };
      });

      setCombinedData(combined);

      if (onFetchCombinedData) {
        const validCombinedData = combined.filter(item => item.nama_dokumen !== 'Unknown');
        onFetchCombinedData(validCombinedData.map(item => item.rfid_tag_id));
      }
    }
  }, [documentsData, rfidData]);

  if (error) return <div>Error: {error}</div>;

  return (
    <table id="combinedTable">
      <thead>
        <tr>
          <th>RFID</th>
          <th>Nama Dokumen</th>
        </tr>
      </thead>
      <tbody id="combinedTableBody">
        {combinedData.map((item) => (
          <tr key={item.rfid_tag_id}>
            <td>{item.rfid_tag_id}</td>
            <td>{item.nama_dokumen}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CombinedFetcher;
