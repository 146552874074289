import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Sidebar from "./components/Sidebar";
import NavBar from "./components/NavBar";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import ScanRoom from "./components/ScanRoom";
import Request from "./components/Request";
import Settings from "./components/Settings";
import AdminMenu from "./components/AdminMenu";
import RFIDMenu from "./components/RFIDMenu";
import Approval from "./components/Approval";
import History from "./components/History";
import DocChanges from "./components/HistoryDocChanges";
import "./App.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [branch, setBranch] = useState(null);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const response = await fetch("https://rfidpartnership.com/api/login", {
          method: "GET",
          credentials: "include",
        });

        if (response.status === 200) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          setUser(null);
          setIsLoggedIn(false); // Set to false if response is not successful
        }
      } catch (err) {
        console.error("Error checking login status:", err);
        setUser(null);
        setIsLoggedIn(false); // Set to false if an error occurs
      }
    };

    checkLogin();
  }, []);

  // Function to handle login
  const handleLogin = (loggedIn, userData) => {
    setIsLoggedIn(loggedIn);
    setUser(userData);
  };

  // Function to handle logout
  const handleLogout = async () => {
    try {
      const response = await fetch("https://rfidpartnership.com/api/logout", {
        method: "GET",
        credentials: "include",
      });

      if (response.status === 200) {
        setIsLoggedIn(false);
        setUser(null);
      } else {
        console.error("Failed to logout");
      }
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  return (
    <Router>
      <div className={`app ${theme}`}>
        {/* Helmet for adding external CSS */}
        <Helmet>
          <link
            href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
            rel="stylesheet"
          />
        </Helmet>

        {/* Conditional rendering based on login state */}
        {isLoggedIn ? (
          <>
            <Sidebar user={user} onLogout={handleLogout} />
            <NavBar theme={theme} setTheme={setTheme} />

            {/* Routes for different components */}
            <Routes>
              <Route
                path="/"
                element={
                  <Dashboard
                    user={user}
                    branch={branch}
                    setBranch={setBranch}
                  />
                }
              />
              <Route path="/scan-room" element={<ScanRoom user={user} />} />
              <Route path="/request" element={<Request user={user} />} />
              <Route path="/rfid" element={<RFIDMenu user={user}/>} />
              <Route path="/admin-menu" element={<AdminMenu user={user} />} />
              <Route path="/approval" element={<Approval user={user} />} />
              <Route path="/history" element={<History user={user} />} />
              <Route path="/history-doc" element={<DocChanges user={user} />} />
              
              <Route
                path="/settings"
                element={
                  <Settings theme={theme} setTheme={setTheme} user={user} />
                }
              />
            </Routes>
          </>
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </div>
    </Router>
  );
}

export default App;
