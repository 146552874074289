import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

function Login({ onLogin }) {
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://rfidpartnership.com/api/login", {
        method: "POST",
        // mode: "cors",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          username: userInfo.username,
          password: userInfo.password,
        }),
      });

      if (response.status === 200) {
        const data = await response.json();
        onLogin(true, data.user);
        navigate("/");
      } else {
        alert("Login failed. Please check your credentials.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      alert("An error occurred while logging in.");
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="logo-section">
          <img src="logo.png" alt="Bank BJB Logo" />
        </div>
        <div className="login-section">
          <h1>Login</h1>
          <form onSubmit={handleLogin}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              autoComplete="off"
              required
              pattern="[a-zA-Z0-9]+"
              onChange={(e) => handleOnChange(e)}
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              autoComplete="off"
              required
              onChange={(e) => handleOnChange(e)}
            />
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
