import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DBFetcher from "./databaseFetcher";
import ExportExcel from "./ExportExcel";

function Dashboard({ user }) {
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [rfidSearch, setRfidSearch] = useState("");
  const [filteredRFIDs, setFilteredRFIDs] = useState([]);
  const [allRFIDs, setAllRFIDs] = useState([]);
  const [usedRFIDs, setUsedRFIDs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [rfidToEdit, setRfidToEdit] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [branchOptions, setBranchOptions] = useState([]);
  const [branch, setBranch] = useState(null);
  const [formData, setFormData] = useState({
    kode_pelaksana: "",
    unit_pengolahan: "",
    tanggal_dokumen: "",
    no_dokumen: "",
    perihal: "",
    nama_dokumen: "",
    tanggal_dokumen_lunas: "",
    kurun_waktu_awal: "",
    kurun_waktu_akhir: "",
    tp: "",
    media_simpan: "",
    kondisi: "",
    jumlah_berkas: "",
    lokasi: "",
    masa_retensi: "",
    status: "",
    no_boks: "",
    rfid_tag_id: "",
    location_id: "",
    rfid_number: "",
  });

  const [locations, setLocations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [racks, setRacks] = useState([]);
  const [rows, setRows] = useState([]);
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch allowed RFIDs
        const allowedResponse = await fetch(
          `https://rfidpartnership.com/api/allowed_rfids?user_id=${user.user_id}`,
          {
            method: "GET",
          }
        );
        if (!allowedResponse.ok) {
          throw new Error(`HTTP error! status: ${allowedResponse.status}`);
        }
        const allowedData = await allowedResponse.json();
        setAllRFIDs(allowedData.documents.map((doc) => doc.rfid_number));

        // Fetch used RFIDs
        const usedResponse = await fetch(
          `https://rfidpartnership.com/api/used_rfids?user_id=${user.user_id}`,
          {
            method: "GET",
          }
        );
        if (!usedResponse.ok) {
          throw new Error(`HTTP error! status: ${usedResponse.status}`);
        }
        const usedData = await usedResponse.json();
        setUsedRFIDs(usedData.documents.map((doc) => doc.rfid_tag_id));
      } catch (err) {}
    };

    fetchData();
  }, [user.user_id]);

  useEffect(() => {
    fetch("https://rfidpartnership.com/api/locations")
      .then((response) => response.json())
      .then((data) => {
        setLocations(data);
        const uniqueRooms = [...new Set(data.map((loc) => loc.room))];
        setRooms(uniqueRooms);
      })
      .catch((err) => console.error(err));

    axios
      .get("https://rfidpartnership.com/api/branch")
      .then((response) => {
        setBranchOptions(
          response.data.documents.map((doc) => ({
            user_id: doc.user_id,
            username: doc.username,
            branch_name: doc.branch_name,
          }))
        );
      })
      .catch((err) => console.error(err));

    console.log(user);
  }, []);

  useEffect(() => {
    if (formData.room) {
      const filteredRacks = locations.filter(
        (loc) => loc.room === formData.room
      );
      const uniqueRacks = [...new Set(filteredRacks.map((loc) => loc.rack))];
      setRacks(uniqueRacks);
      setRows([]);
      setBoxes([]);
      setFormData({ ...formData, rack: "", row: "", box: "" });
    }
  }, [formData.room]);

  useEffect(() => {
    if (formData.rack) {
      const filteredRows = locations.filter(
        (loc) => loc.room === formData.room && loc.rack === formData.rack
      );
      const uniqueRows = [...new Set(filteredRows.map((loc) => loc.row))];
      setRows(uniqueRows);
      setBoxes([]);
      setFormData({ ...formData, row: "", box: "" });
    }
  }, [formData.rack]);

  useEffect(() => {
    if (formData.row) {
      const filteredBoxes = locations.filter(
        (loc) =>
          loc.room === formData.room &&
          loc.rack === formData.rack &&
          loc.row === formData.row
      );
      const uniqueBoxes = [...new Set(filteredBoxes.map((loc) => loc.box))];
      setBoxes(uniqueBoxes);
      setFormData({ ...formData, box: "" });
    }
  }, [formData.row]);

  useEffect(() => {
    if (formData.box) {
      setFormData({ ...formData });
    }
  }, [formData.box]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (selectedOption) {
      const selectedBranch = branchOptions.find(
        (option) => option.user_id === selectedOption
      );
      if (selectedBranch) {
        console.log("Use effect:", selectedBranch);
        setBranch(selectedBranch);
      }
    }
  }, [selectedOption, branchOptions]);

  const handleOptionChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedOption(selectedValue.branch_name);
    console.log("Selected Value:", selectedValue);
    console.log("Selection Option:", selectedOption);
    setBranch(selectedValue);
  };

  const showModal = (modalType) => {
    if (modalType === "add") {
      setShowAddModal(true);
    } else if (modalType === "edit") {
      setShowEditModal(true);
    } else if (modalType === "upload") {
      setShowUploadModal(true);
    }
  };

  const closeModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowUploadModal(false);
    setFilteredRFIDs([]);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleRfidSearch = (event) => {
    const value = event.target.value;
    setRfidSearch(value);
    console.log("Input value:", value);
    console.log("All RFIDs:", allRFIDs);

    if (value) {
      const filtered = allRFIDs.filter((rfid_number) =>
        rfid_number.toLowerCase().includes(value.toLowerCase())
      );
      console.log("Filtered RFIDs:", filtered);
      setFilteredRFIDs(filtered);
    } else {
      setFilteredRFIDs([]);
    }
  };

  const handleRfidSelect = (rfid_tag_id) => {
    setRfidSearch(rfid_tag_id);
    setFilteredRFIDs([]);
    console.log("RFID selected:", rfid_tag_id);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCombinedChange = (e) => {
    handleRfidSearch(e);
    setFormData({ ...formData, rfid_tag_id: e.target.value });
    setRfidSearch(e.target.value);
  };

  const handleRowClick = (rfid_tag_id) => {
    if (user.type !== "admin" && user.type !== "superadmin") {
      console.log(`Row clicked with RFID: ${rfid_tag_id}`);
      setRfidToEdit(rfid_tag_id);
      showModal("edit");

      fetch(`https://rfidpartnership.com/api/documents/${rfid_tag_id}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("Data fetched:", data);
          setFormData(data.document);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleFormSubmitAddDoc = (event) => {
    event.preventDefault();

    // Extract location details from formData
    const { room, rack, row, box } = formData;

    // Find matching location_id based on selected room, rack, row, box
    const selectedLocation = locations.find(
      (loc) =>
        loc.room === room &&
        loc.rack === rack &&
        loc.row === row &&
        loc.box === box
    );

    if (!selectedLocation) {
      console.error("Location not found!");
      return;
    }

    const { location_id } = selectedLocation;

    // Prepare document data for submission
    const {
      kode_pelaksana,
      kode_klas,
      unit_pengolahan,
      tanggal_dokumen,
      no_dokumen,
      perihal,
      nama_dokumen,
      tanggal_dokumen_lunas,
      kurun_waktu_awal,
      kurun_waktu_akhir,
      tp,
      media_simpan,
      kondisi,
      jumlah_berkas,
      masa_retensi,
      status,
    } = formData;
    console.log("Form data:", formData);

    // Perform API request to submit the document data
    fetch("https://rfidpartnership.com/api/documents", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        kode_pelaksana,
        user_id: user.user_id,
        kode_klas,
        unit_pengolahan,
        tanggal_dokumen,
        no_dokumen,
        perihal,
        nama_dokumen,
        tanggal_dokumen_lunas,
        kurun_waktu_awal,
        kurun_waktu_akhir,
        tp,
        media_simpan,
        kondisi,
        jumlah_berkas,
        masa_retensi,
        status,
        rfid_tag_id: rfidSearch,
        location_id: location_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
        }
        return response.json();
      })
      .then((data) => {
        console.log("Document created successfully:", data);
        closeModal();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error creating document:", error);
        window.alert("RFID is not available or not assigned to this user.");
      });
  };

  const handleFormSubmitEditDoc = () => {
    // Extract location details from formData
    const { room, row, rack, box } = formData;

    // Find matching location_id based on selected room, row, rack, box
    const selectedLocation = locations.find(
      (loc) =>
        loc.room === room &&
        loc.rack === rack &&
        loc.row === row &&
        loc.box === box
    );

    if (!selectedLocation) {
      console.error("Location not found!");
      return;
    }

    const { location_id } = selectedLocation;
    const { document_id } = formData;
    console.log("Document ID:", document_id);

    const rfid_tag_id = rfidSearch || formData.rfid_tag_id;

    // Prepare document data for submission
    const {
      kode_pelaksana,
      kode_klas,
      unit_pengolahan,
      tanggal_dokumen,
      no_dokumen,
      perihal,
      nama_dokumen,
      tanggal_dokumen_lunas,
      kurun_waktu_awal,
      kurun_waktu_akhir,
      tp,
      media_simpan,
      kondisi,
      jumlah_berkas,
      masa_retensi,
      status,
    } = formData;

    // Perform API request to edit the document data
    fetch(`https://rfidpartnership.com/api/documents/${document_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        kode_pelaksana,
        user_id: user.user_id,
        kode_klas,
        unit_pengolahan,
        tanggal_dokumen,
        no_dokumen,
        perihal,
        nama_dokumen,
        tanggal_dokumen_lunas,
        kurun_waktu_awal,
        kurun_waktu_akhir,
        tp,
        media_simpan,
        kondisi,
        jumlah_berkas,
        masa_retensi,
        status,
        rfid_tag_id: rfid_tag_id,
        location_id: location_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Document updated successfully:", data);
        closeModal();
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });
  };

  const handleFormSubmitDeleteDoc = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      // Perform API request to delete the document
      fetch(`https://rfidpartnership.com/api/documents/${rfidToEdit}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Document deleted successfully:", data);
          navigate(0);
          closeModal();
        })
        .catch((error) => {
          console.error("Error deleting document:", error);
        });
    }
  };

  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvPreview, setCsvPreview] = useState([]);
  const [fetchData, setFetchData] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Read the CSV file and update preview state
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n").map((row) => row.split(","));
      setCsvPreview(rows);
    };
    reader.readAsText(file);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setCsvPreview([]);
    fileInputRef.current.value = "";
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile); // Ensure the 'name' matches the server's expected field
    formData.append("user_id", user.user_id); // Correctly append user_id to formData

    try {
      // Update the URL and endpoint to match the new API route
      const response = await fetch("https://rfidpartnership.com/api/upload/csv", {
        method: "POST",
        body: formData, // Now formData includes both the file and user_id
      });

      if (response.ok) {
        console.log("File uploaded and processed successfully");
        setFetchData(true); // Trigger fetching data
        alert("File uploaded and processed successfully");
        closeModal();
      } else {
        console.error("Failed to upload and process file");
      }
    } catch (error) {
      console.error("Error uploading and processing file:", error);
    }
  };

  const renderCsvPreview = () => {
    if (csvPreview.length === 0) return null;

    // Extract headers and data rows
    const headers = csvPreview[0];
    const dataRows = csvPreview.slice(1);
    const previewRows = dataRows.slice(0, 5);
    const totalRows = dataRows.length;

    return (
      <div className="csv-preview">
        <h3>CSV Preview:</h3>
        <p>Total rows: {totalRows}</p>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  style={{ paddingRight: "24px", textWrap: "nowrap" }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {previewRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{ paddingRight: "24px", textWrap: "nowrap" }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Dashboard</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>Database Views</h3>
              <div className="header-container">
                {(user.type === "admin" || user.type === "superadmin") && (
                  <select
                    className="search-bar uppercase"
                    value={
                      branchOptions.find(
                        (option) => option.branch_name === selectedOption
                      )
                        ? JSON.stringify(
                            branchOptions.find(
                              (option) => option.branch_name === selectedOption
                            )
                          )
                        : ""
                    }
                    onChange={handleOptionChange}
                  >
                    <option value="" disabled>
                      Pilih Cabang
                    </option>
                    {branchOptions.map((option) => (
                      <option
                        key={option.user_id}
                        value={JSON.stringify(option)}
                        className="uppercase"
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                )}
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />

                {user.type !== "admin" && user.type !== "superadmin" ? (
                  <>
                    <i
                      className="bx bx-plus"
                      onClick={() => showModal("add")}
                    ></i>
                    <ExportExcel
                      user_id={user.user_id}
                      user_name={user.username}
                    />
                    <i
                      className="bx bx-cloud-upload"
                      onClick={() => showModal("upload")}
                    ></i>
                  </>
                ) : branch ? (
                  <ExportExcel user_id={branch.user_id} user_name={branch.username} />
                ) : null}
              </div>
            </div>
            <div className="DataDisplay">
              {user.type !== "admin" && user.type !== "superadmin" ? (
                <DBFetcher
                  returnType="table"
                  searchQuery={searchQuery}
                  onRowClick={handleRowClick}
                  user={user}
                  selectedOption={selectedOption}
                />
              ) : (
                <DBFetcher
                  returnType="table"
                  searchQuery={searchQuery}
                  onRowClick={handleRowClick}
                  user={user}
                  branch={branch}
                  selectedOption={selectedOption}
                />
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Assign RFID Modal */}
      <div className={`modal ${showAddModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Assign RFID</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="addDocument" onSubmit={handleFormSubmitAddDoc}>
              <label htmlFor="rfid_tag_id">RFID :</label>
              <input
                type="text"
                id="rfid_tag_id"
                name="rfid_tag_id"
                value={rfidSearch}
                onChange={handleCombinedChange}
                autoComplete="off"
                required
              />
              {filteredRFIDs.length > 0 && (
                <ul className="dropdown">
                  {filteredRFIDs.map((rfid_number, index) => (
                    <li
                      key={index}
                      onClick={() => handleRfidSelect(rfid_number)}
                    >
                      {rfid_number}
                    </li>
                  ))}
                </ul>
              )}

              <label htmlFor="kode_klas">Kode Klas :</label>
              <input
                type="text"
                id="kode_klas"
                name="kode_klas"
                onChange={(e) =>
                  setFormData({ ...formData, kode_klas: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="kode_pelaksana">Kode Pelaksana :</label>
              <input
                type="text"
                id="kode_pelaksana"
                name="kode_pelaksana"
                onChange={(e) =>
                  setFormData({ ...formData, kode_pelaksana: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="unit_pengolahan">Unit Pengolahan :</label>
              <input
                type="text"
                id="unit_pengolahan"
                name="unit_pengolahan"
                onChange={(e) =>
                  setFormData({ ...formData, unit_pengolahan: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="tanggal_dokumen">Tanggal Dokumen :</label>
              <input
                type="date"
                id="tanggal_dokumen"
                name="tanggal_dokumen"
                onChange={(e) =>
                  setFormData({ ...formData, tanggal_dokumen: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="no_dokumen">No Dokumen :</label>
              <input
                type="text"
                id="no_dokumen"
                name="no_dokumen"
                onChange={(e) =>
                  setFormData({ ...formData, no_dokumen: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="perihal">Perihal :</label>
              <input
                type="text"
                id="perihal"
                name="perihal"
                onChange={(e) =>
                  setFormData({ ...formData, perihal: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="nama_dokumen">Nama Dokumen :</label>
              <input
                type="text"
                id="nama_dokumen"
                name="nama_dokumen"
                onChange={(e) =>
                  setFormData({ ...formData, nama_dokumen: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="tanggal_dokumen_lunas">
                Tanggal Dokumen Lunas :
              </label>
              <input
                type="date"
                id="tanggal_dokumen_lunas"
                name="tanggal_dokumen_lunas"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tanggal_dokumen_lunas: e.target.value,
                  })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="kurun_waktu_awal">Kurun Waktu Awal :</label>
              <input
                type="date"
                id="kurun_waktu_awal"
                name="kurun_waktu_awal"
                onChange={(e) =>
                  setFormData({ ...formData, kurun_waktu_awal: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="kurun_waktu_akhir">Kurun Waktu Akhir :</label>
              <input
                type="date"
                id="kurun_waktu_akhir"
                name="kurun_waktu_akhir"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    kurun_waktu_akhir: e.target.value,
                  })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="tp">TP :</label>
              <input
                type="text"
                id="tp"
                name="tp"
                onChange={(e) =>
                  setFormData({ ...formData, tp: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="media_simpan">Media Simpan :</label>
              <input
                type="text"
                id="media_simpan"
                name="media_simpan"
                onChange={(e) =>
                  setFormData({ ...formData, media_simpan: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="kondisi">Kondisi :</label>
              <input
                type="text"
                id="kondisi"
                name="kondisi"
                onChange={(e) =>
                  setFormData({ ...formData, kondisi: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="jumlah_berkas">Jumlah Berkas :</label>
              <input
                type="number"
                min={0}
                id="jumlah_berkas"
                name="jumlah_berkas"
                onChange={(e) =>
                  setFormData({ ...formData, jumlah_berkas: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="masa_retensi">Masa Retensi :</label>
              <input
                type="date"
                id="masa_retensi"
                name="masa_retensi"
                onChange={(e) =>
                  setFormData({ ...formData, masa_retensi: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="status">Status :</label>
              <input
                type="text"
                id="status"
                name="status"
                onChange={(e) =>
                  setFormData({ ...formData, status: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="room">Ruangan :</label>
              <select
                id="room"
                name="room"
                required
                value={formData.room}
                onChange={handleChange}
              >
                <option value="">Pilih Ruangan</option>
                {rooms.map((room) => (
                  <option key={room} value={room}>
                    {room}
                  </option>
                ))}
              </select>

              <label htmlFor="rack">Rak :</label>
              <select
                id="rack"
                name="rack"
                required
                value={formData.rack}
                onChange={handleChange}
                disabled={!formData.room}
              >
                <option value="">Pilih Rak</option>
                {racks.map((rack) => (
                  <option key={rack} value={rack}>
                    {rack}
                  </option>
                ))}
              </select>

              <label htmlFor="row">Baris :</label>
              <select
                id="row"
                name="row"
                required
                value={formData.row}
                onChange={handleChange}
                disabled={!formData.rack}
              >
                <option value="">Pilih Baris</option>
                {rows.map((row) => (
                  <option key={row} value={row}>
                    {row}
                  </option>
                ))}
              </select>

              <label htmlFor="box">Boks :</label>
              <select
                id="box"
                name="box"
                required
                value={formData.box}
                onChange={handleChange}
                disabled={!formData.row}
              >
                <option value="">Pilih Boks</option>
                {boxes.map((box) => (
                  <option key={box} value={box}>
                    {box}
                  </option>
                ))}
              </select>

              <button className="modal-button edit" type="submit">
                Assign
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Edit Form Modal */}
      <div className={`modal ${showEditModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>RFID Details</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="editDocument">
              <label htmlFor="rfid_tag_id">RFID :</label>
              <input
                type="text"
                id="rfid_tag_id"
                name="rfid_tag_id"
                value={rfidSearch || formData.rfid_tag_id}
                onChange={handleCombinedChange}
                autoComplete="off"
                required
              />
              {filteredRFIDs.length > 0 && (
                <ul className="dropdown">
                  {filteredRFIDs.map((rfid_number, index) => (
                    <li
                      key={index}
                      onClick={() => handleRfidSelect(rfid_number)}
                    >
                      {rfid_number}
                    </li>
                  ))}
                </ul>
              )}
              <label htmlFor="kode_klas">Kode Klas :</label>
              <input
                type="text"
                id="kode_klas"
                name="kode_klas"
                value={formData.kode_klas}
                onChange={(e) =>
                  setFormData({ ...formData, kode_klas: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="kode_pelaksana">Kode Pelaksana :</label>
              <input
                type="text"
                id="kode_pelaksana"
                name="kode_pelaksana"
                value={formData.kode_pelaksana}
                onChange={(e) =>
                  setFormData({ ...formData, kode_pelaksana: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="unit_pengolahan">Unit Pengolahan :</label>
              <input
                type="text"
                id="unit_pengolahan"
                name="unit_pengolahan"
                value={formData.unit_pengolahan}
                onChange={(e) =>
                  setFormData({ ...formData, unit_pengolahan: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="tanggal_dokumen">Tanggal Dokumen :</label>
              <input
                type="date"
                id="tanggal_dokumen"
                name="tanggal_dokumen"
                value={formData.tanggal_dokumen}
                onChange={(e) =>
                  setFormData({ ...formData, tanggal_dokumen: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="no_dokumen">No Dokumen :</label>
              <input
                type="text"
                id="no_dokumen"
                name="no_dokumen"
                value={formData.no_dokumen}
                onChange={(e) =>
                  setFormData({ ...formData, no_dokumen: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="perihal">Perihal :</label>
              <input
                type="text"
                id="perihal"
                name="perihal"
                value={formData.perihal}
                onChange={(e) =>
                  setFormData({ ...formData, perihal: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="nama_dokumen">Nama Dokumen :</label>
              <input
                type="text"
                id="nama_dokumen"
                name="nama_dokumen"
                value={formData.nama_dokumen}
                onChange={(e) =>
                  setFormData({ ...formData, nama_dokumen: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="tanggal_dokumen_lunas">
                Tanggal Dokumen Lunas :
              </label>
              <input
                type="date"
                id="tanggal_dokumen_lunas"
                name="tanggal_dokumen_lunas"
                value={formData.tanggal_dokumen_lunas}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tanggal_dokumen_lunas: e.target.value,
                  })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="kurun_waktu_awal">Kurun Waktu Awal :</label>
              <input
                type="date"
                id="kurun_waktu_awal"
                name="kurun_waktu_awal"
                value={formData.kurun_waktu_awal}
                onChange={(e) =>
                  setFormData({ ...formData, kurun_waktu_awal: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="kurun_waktu_akhir">Kurun Waktu Akhir :</label>
              <input
                type="date"
                id="kurun_waktu_akhir"
                name="kurun_waktu_akhir"
                value={formData.kurun_waktu_akhir}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    kurun_waktu_akhir: e.target.value,
                  })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="tp">TP :</label>
              <input
                type="text"
                id="tp"
                name="tp"
                value={formData.tp}
                onChange={(e) =>
                  setFormData({ ...formData, tp: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="media_simpan">Media Simpan :</label>
              <input
                type="text"
                id="media_simpan"
                name="media_simpan"
                value={formData.media_simpan}
                onChange={(e) =>
                  setFormData({ ...formData, media_simpan: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="kondisi">Kondisi :</label>
              <input
                type="text"
                id="kondisi"
                name="kondisi"
                value={formData.kondisi}
                onChange={(e) =>
                  setFormData({ ...formData, kondisi: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="jumlah_berkas">Jumlah Berkas :</label>
              <input
                type="number"
                min={0}
                id="jumlah_berkas"
                name="jumlah_berkas"
                value={formData.jumlah_berkas}
                onChange={(e) =>
                  setFormData({ ...formData, jumlah_berkas: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="masa_retensi">Masa Retensi :</label>
              <input
                type="date"
                id="masa_retensi"
                name="masa_retensi"
                value={formData.masa_retensi}
                onChange={(e) =>
                  setFormData({ ...formData, masa_retensi: e.target.value })
                }
                autoComplete="off"
                required
              />
              <label htmlFor="status">Status :</label>
              <input
                type="text"
                id="status"
                name="status"
                value={formData.status}
                onChange={(e) =>
                  setFormData({ ...formData, status: e.target.value })
                }
                autoComplete="off"
                required
              />

              <label htmlFor="room">Ruangan :</label>
              <select
                id="room"
                name="room"
                required
                value={formData.room}
                onChange={handleChange}
              >
                <option value="">Pilih Ruangan</option>
                {rooms.map((room) => (
                  <option key={room} value={room}>
                    {room}
                  </option>
                ))}
              </select>

              <label htmlFor="rack">Rak :</label>
              <select
                id="rack"
                name="rack"
                required
                value={formData.rack}
                onChange={handleChange}
                disabled={!formData.room}
              >
                <option value="">Pilih Rak</option>
                {racks.map((rack) => (
                  <option key={rack} value={rack}>
                    {rack}
                  </option>
                ))}
              </select>

              <label htmlFor="row">Baris :</label>
              <select
                id="row"
                name="row"
                required
                value={formData.row}
                onChange={handleChange}
                disabled={!formData.rack}
              >
                <option value="">Pilih Baris</option>
                {rows.map((row) => (
                  <option key={row} value={row}>
                    {row}
                  </option>
                ))}
              </select>

              <label htmlFor="box">Boks :</label>
              <select
                id="box"
                name="box"
                required
                value={formData.box}
                onChange={handleChange}
                disabled={!formData.row}
              >
                <option value="">Pilih Boks</option>
                {boxes.map((box) => (
                  <option key={box} value={box}>
                    {box}
                  </option>
                ))}
              </select>

              <div className="modal-content-buttons">
                <button
                  className="modal-button edit"
                  type="button"
                  onClick={handleFormSubmitEditDoc}
                >
                  Edit
                </button>
                <button
                  className="modal-button delete"
                  type="button"
                  onClick={handleFormSubmitDeleteDoc}
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Upload CSV Modal */}
      <div className={`modal ${showUploadModal ? "open" : ""}`}>
        <div
          className="modal-content settings-view"
          style={{ maxWidth: "768px", textAlign: "left" }}
        >
          <div className="modal-content-header">
            <h2>Upload CSV</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <div className="upload-csv-container">
              <div className="file-input-container">
                <div className="file-input">
                  <label htmlFor="csvFile">Upload CSV:</label>
                  <input
                    type="file"
                    id="csvFile"
                    name="csvFile"
                    accept=".csv"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                {renderCsvPreview()}
                <div className="file-buttons">
                  <button onClick={handleClearFile}>Clear</button>
                  <button onClick={handleFileUpload} disabled={!selectedFile}>
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
