import React, { useEffect } from "react";

function NavBar({ theme, setTheme }) {
  const handleMenuBarClick = () => {
    const sideBar = document.querySelector(".sidebar");
    if (sideBar) {
      sideBar.classList.toggle("close");
    }
  };

  // Effect to apply theme-based styles to the body
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("dark", theme === "dark");
      body.classList.toggle("light", theme === "light");
    }
  }, [theme]);

  return (
    <div className="content">
      <nav>
        <i className="bx bx-menu" onClick={handleMenuBarClick}></i>
        <form action="#"></form>
        <input
          type="checkbox"
          id="theme-toggle"
          hidden
          checked={theme === "dark"} // Sync with current theme state
          onChange={() => setTheme(theme === "light" ? "dark" : "light")}
        />
        <label htmlFor="theme-toggle" className="theme-toggle"></label>
      </nav>
    </div>
  );
}

export default NavBar;
