import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DocChangesFetcher = ({ currentItems = [], searchQuery = "", user }) => {
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fetch(
      `https://rfidpartnership.com/api/docchanges?user_id=${user.user_id}&user_type=${user.type}`
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.documents);
        console.log(data.documents);
      })
      .catch((err) => console.error(err));
  }, [user]);

  if (!data.length) {
    return "No data available";
  }

  // Normalize the currentItems for comparison
  const normalizedCurrentItems = currentItems.map((item) =>
    item.trim().toLowerCase()
  );

  // Filter data based on searchQuery
  const filteredData = data.filter(
    (doc) =>
      doc.document_id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kode_pelaksana.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.user_id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kode_klas.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.unit_pengolahan.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.tanggal_dokumen.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.no_dokumen.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.perihal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.nama_dokumen.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.tanggal_dokumen_lunas
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kurun_waktu_awal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.kurun_waktu_akhir.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.tp.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.media_simpan.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.kondisi.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.jumlah_berkas
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.masa_retensi.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.rfid_tag_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.room.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.rack.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.row.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.box.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or empty

    // Ensure dateString is a string and then proceed with replace method
    if (typeof dateString !== "string") {
      dateString = dateString.toString();
    }

    return dateString.replace("T", " ").replace("Z", "").split(".")[0];
  };

  return (
    <div>
      <table id="databaseTable">
        <thead>
          <tr>
            <th colSpan="2">Total History Perubahan: {filteredData.length}</th>
          </tr>
          <tr>
            <th>ID Perubahan</th>
            <th>ID Dokumen</th>
            <th>Pemilik Dokumen</th>
            <th>Kode Pelaksana</th>
            <th>Kode Klas</th>
            <th>Unit Pengolahan</th>
            <th>Tanggal Dokumen</th>
            <th>No Dokumen</th>
            <th>Perihal</th>
            <th>Nama Dokumen</th>
            <th>Tanggal Dokumen Lunas</th>
            <th>Kurun Waktu Awal</th>
            <th>Kurun Waktu Akhir</th>
            <th>TP</th>
            <th>Media Simpan</th>
            <th>Kondisi</th>
            <th>Jumlah Berkas</th>
            <th>Masa Retensi</th>
            <th>Status</th>
            <th>RFID Tag ID</th>
            <th>Ruangan</th>
            <th>Rak</th>
            <th>Baris</th>
            <th>Box</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          {filteredData.map((doc, index) => (
            <tr key={index}>
              <td>{doc.change_id}</td>
              <td>{doc.document_id}</td>
              <td>{doc.changed_by_user_id}</td>
              <td>{doc.old_kode_pelaksana}</td>
              <td>{doc.old_kode_klas}</td>
              <td>{doc.old_unit_pengolahan}</td>
              <td>{formatDate(doc.old_tanggal_dokumen)}</td>
              <td>{doc.old_no_dokumen}</td>
              <td>{doc.old_perihal}</td>
              <td>{doc.old_nama_dokumen}</td>
              <td>{formatDate(doc.old_tanggal_dokumen_lunas)}</td>
              <td>{formatDate(doc.old_kurun_waktu_awal)}</td>
              <td>{formatDate(doc.old_kurun_waktu_akhir)}</td>
              <td>{doc.old_tp}</td>
              <td>{doc.old_media_simpan}</td>
              <td>{doc.old_kondisi}</td>
              <td>{doc.old_jumlah_berkas}</td>
              <td>{formatDate(doc.old_masa_retensi)}</td>
              <td>{doc.old_status}</td>
              <td>{doc.old_rfid_tag_id}</td>
              <td>{doc.room}</td>
              <td>{doc.rack}</td>
              <td>{doc.row}</td>
              <td>{doc.box}</td>  
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DocChangesFetcher;
