import React, { useState, useEffect, useRef } from "react";

function Settings({ theme, setTheme, user }) {
  // const [user, setUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    username: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    room: "",
    rack: "",
    row: "",
    box: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("logo", selectedFile);

      const response = await fetch("https://rfidpartnership.com/api/upload/logo", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Logo uploaded successfully!");
        setSelectedFile(null);
        setPreviewImage(null);

        // Clear the file input field
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        const data = await response.json();
        alert(`Failed to upload logo: ${data.error}`);
      }
    } catch (error) {
      console.error("Error uploading logo:", error);
      alert("Failed to upload logo. Please try again.");
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setPreviewImage(null);
    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUserChangePassword = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = formData;

    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match");
      return;
    }

    try {
      const response = await fetch(
        `https://rfidpartnership.com/api/users/${user.user_id}/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      alert(data.message);
    } catch (error) {
      console.error("Error changing password:", error);
      alert(`Failed to change password. Please try again. ${error}`); // Example of client-side alert
    }
  };

  const handleAddNewLocation = async () => {
    const { room, rack, row, box } = formData;

    try {
      const response = await fetch("https://rfidpartnership.com/api/locations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ room, rack, row, box }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      alert(data.message); // Assuming the server sends { message: "Location created successfully" }

      // Optionally, clear form fields after successful submission
      setFormData({
        room: "",
        rack: "",
        row: "",
        box: "",
      });

    } catch (error) {
      console.error("Error creating location:", error);
      alert(`Failed to create location. Please try again. ${error}`);
    }
  };

  // document.addEventListener('DOMContentLoaded', () => {
  //   const passwordForm = document.getElementById('passwordChangeForm'); // Assuming your form has an ID 'passwordChangeForm'
  //   passwordForm.addEventListener('submit', async (event) => {
  //     event.preventDefault(); // Prevent the form from submitting in the traditional way

  //     // Extract form data
  //     const formData = new FormData(passwordForm);
  //     const username = formData.get('username'); // Assuming there's an input with name='username'
  //     const oldPassword = formData.get('oldPassword'); // Assuming there's an input with name='oldPassword'
  //     const newPassword = formData.get('newPassword'); // Assuming there's an input with name='newPassword'
  //     const confirmPassword = formData.get('confirmPassword'); // Assuming there's an input with name='confirmPassword'

  //     // Call the handler function with the form data
  //     await handleUserChangePassword(username, oldPassword, newPassword, confirmPassword);
  //   });
  // });

  // Effect to apply theme-based styles to the body
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("dark", theme === "dark");
      body.classList.toggle("light", theme === "light");
    }
  }, [theme]);

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Settings</h1>
          </div>
        </div>

        <div className="bottom-data" style={{ flexDirection: "column" }}>
          <div className="settings-view">
            <div className="header">
              <h3>Appearance</h3>
            </div>
            <form action="#">
              <div className="theme-options">
                <div className="option-container">
                  <input
                    type="radio"
                    id="light-theme"
                    name="theme"
                    value="light"
                    checked={theme === "light"}
                    onChange={() => setTheme("light")}
                  />
                  <label htmlFor="light-theme">
                    Light theme<i className="bx bxs-sun"></i>
                  </label>
                </div>
                <div className="option-container">
                  <input
                    type="radio"
                    id="dark-theme"
                    name="theme"
                    value="dark"
                    checked={theme === "dark"}
                    onChange={() => setTheme("dark")}
                  />
                  <label htmlFor="dark-theme">
                    Dark theme <i className="bx bxs-moon"></i>
                  </label>
                </div>
              </div>
            </form>
          </div>

          <div className="settings-view">
            <div className="header">
              <h3>Change Password</h3>
            </div>
            <form id="passwordChangeForm" onSubmit={handleUserChangePassword}>
              <div>
                <label htmlFor="oldPassword">Current Password:</label>
                <input
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="newPassword">New Password:</label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="confirmPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <br />
                <br />
                <button type="submit" className="modal-button edit">
                  Change Password
                </button>
              </div>
            </form>
          </div>

          {user.type === "admin" ? (
            <div className="settings-view">
              <div className="header">
                <h3>Change Logo</h3>
              </div>
              <div className="change-logo-container">
                <div className="file-input-container">
                  <div className="file-input">
                    <label htmlFor="logo">Upload logo:</label>
                    <input
                      type="file"
                      id="logo"
                      name="logo"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                  <div className="file-buttons">
                    <button onClick={handleClearFile}>Clear</button>
                    <button onClick={handleFileUpload} disabled={!selectedFile}>
                      Upload
                    </button>
                  </div>
                </div>
                <div className="file-preview-container">
                  {previewImage && (
                    <div className="file-preview">
                      <img src={previewImage} alt="Preview" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="settings-view">
              <div className="header">
                <h3>Add New Location</h3>
              </div>
              <form id="passwordChangeForm" onSubmit={handleAddNewLocation}>
                <div>
                  <label htmlFor="oldPassword">Ruangan :</label>
                  <input
                    type="text"
                    id="room"
                    name="room"
                    value={formData.room}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="rack">Rak :</label>
                  <input
                    type="text"
                    id="rack"
                    name="rack"
                    value={formData.rack}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="row">Baris :</label>
                  <input
                    type="text"
                    id="row"
                    name="row"
                    value={formData.row}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="box">Boks :</label>
                  <input
                    type="text"
                    id="box"
                    name="box"
                    value={formData.box}
                    onChange={handleChange}
                    required
                  />
                  <br />
                  <br />
                  <button type="submit" className="modal-button edit">
                    Add Location
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default Settings;
