import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { writeFile, utils } from "xlsx";

const RFIDFetcher = ({
  searchQuery,
  filterStatus,
  handleRfidDelete,
  handleRfidEdit,
  user,
}) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    console.log("user", user);
    fetch(
      `https://rfidpartnership.com/api/all_allowed_rfids/?user_id=${user.user_id}&type=${user.type}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.documents); // Assuming endpoint returns { documents: [] }
      })
      .catch((error) => setError(error.message));
  }, []);

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "RFID Data");
    writeFile(workbook, "RFIDData.xlsx");
  };

  const getRFIDDataTable = (data, searchQuery, filterStatus, onRowClick) => {
    if (!Array.isArray(data) || data.length === 0) {
      return <div>No data available</div>;
    }

    const filteredData = data.filter(
      (rfid) =>
        rfid.rfid_number.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (filterStatus === "" ||
          rfid.status.toLowerCase() === filterStatus.toLowerCase())
    );

    const formatDate = (dateString) => {
      if (!dateString) return ""; // Handle case where dateString is undefined or empty

      // Ensure dateString is a string and then proceed with replace method
      if (typeof dateString !== "string") {
        dateString = dateString.toString();
      }

      return dateString.replace("T", " ").replace("Z", "").split(".")[0];
    };

    return (
      <div className="table-container">
        <h3>Jumlah RFID : {data.length}</h3>
        <table className="user-table">
          <thead>
            <tr>
              <th>RFID Number</th>
              <th>Nomor Dokumen</th>
              <th>Tanggal Dibuat</th>
              <th>RFID Status</th>
              <th>Action</th>
              <th><i className='bx bx-download' onClick={exportToExcel}></i></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((rfid) => (
              <tr key={rfid.rfid_number} onClick={() => onRowClick(rfid)}>
                <td>{rfid.rfid_number}</td>
                <td>{rfid.document}</td>
                <td>{formatDate(rfid.createdAt)}</td>
                <td className="capitalize">{rfid.status}</td>
                <td>
                  <button
                    className="modal-button delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRfidDelete(rfid.rfid_number);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {getRFIDDataTable(data, searchQuery, filterStatus, handleRfidEdit)}
    </div>
  );
};

export default RFIDFetcher;
