import React, { useState, useEffect } from "react";
import ExportExcel from "./ExportExcel";
import RFIDFetcher from "./RFIDFetcher";

function RFIDMenu({ user }) {
  const [showAddRfidModal, setShowAddRfidModal] = useState(false);
  const [showEditRfidModal, setShowEditRfidModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState(""); // Added filter status
  const [rfidToEdit, setRfidToEdit] = useState(null);
  const [rfidData, setRfidData] = useState([]);
  const [rfidFormData, setRfidFormData] = useState({
    rfid_number: "",
    rfid_used_status: ""
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10); // 10 items per page

  useEffect(() => {
    fetchRfidData();
  }, []);

  const fetchRfidData = () => {
    fetch("https://rfidpartnership.com/api/allowed_rfids")
      .then((response) => response.json())
      .then((data) => setRfidData(data.documents))
      .catch((err) => console.error(err));
  };

  const showModal = (modalType, rfid = null) => {
    if (modalType === "addRfid") {
      setShowAddRfidModal(true);
      setRfidFormData({
        rfid_number: "",
        rfid_used_status: ""
      });
    } else if (modalType === "editRfid") {
      setShowEditRfidModal(true);
      setRfidToEdit(rfid);
      setRfidFormData({
        rfid_number: rfid.rfid_number,
        rfid_used_status: rfid.rfid_used_status
      });
    }
  };

  const closeModal = () => {
    setShowAddRfidModal(false);
    setShowEditRfidModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleStatusFilterChange = (event) => { // New handler for status filter
    setFilterStatus(event.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleRfidFormChange = (event) => {
    const { name, value } = event.target;
    setRfidFormData({ ...rfidFormData, [name]: value });
  };

  const handleRfidRowClick = (rfid) => {
    setRfidToEdit(rfid);
    showModal("editRfid", rfid);
  };

  const handleRfidFormSubmit = () => {
    if (showAddRfidModal) {
      // Add rfid
      fetch("https://rfidpartnership.com/api/allowed_rfids", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(rfidFormData)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          fetchRfidData(); // Refresh data after adding rfid
          closeModal();
        })
        .catch((error) => console.error("Error adding rfid:", error));
    } else if (showEditRfidModal) {
      // Edit rfid
      fetch(`https://rfidpartnership.com/api/allowed_rfids/${rfidToEdit.rfid_number}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(rfidFormData)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          fetchRfidData(); // Refresh data after editing rfid
          closeModal();
        })
        .catch((error) => console.error("Error editing rfid:", error));
    }
  };

  const handleRfidDelete = (rfidNumber) => {
    const confirmed = window.confirm("Apakah yakin ingin menghapus RFID?");
    if (confirmed) {
      fetch(`https://rfidpartnership.com/api/allowed_rfids/${rfidNumber}`, {
        method: "DELETE"
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const updatedRfidData = rfidData.filter((rfid) => rfid.rfid !== rfidNumber);
          setRfidData(updatedRfidData);
          alert("RFID deleted successfully. Please refresh.");
        })
        .catch((error) => console.error("Error deleting rfid:", error));
    }
  };
  
  // Pagination logic
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentRfidData = rfidData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(rfidData.length / perPage);

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>RFID Menu</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>List RFIDs</h3>
              <div className="header-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <label htmlFor="status-filter">Status:</label>
                <select
                    className="search-bar"
                  value={filterStatus}
                  onChange={handleStatusFilterChange}
                >
                  <option value="">All</option>
                  <option value="used">Used</option>
                  <option value="available">Available</option>
                </select>
              </div>
            </div>
            {/* Assuming RFIDFetcher component handles rendering of RFIDs */}
            <RFIDFetcher
              rfids={currentRfidData}
              searchQuery={searchQuery}
              filterStatus={filterStatus}
              handleRfidDelete={handleRfidDelete}
              handleRfidEdit={handleRfidRowClick}
              user={user}
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default RFIDMenu;
