// Sidebar.js
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";

function Sidebar({ user, onLogout }) {
  const location = useLocation();

  useEffect(() => {
    const sideLinks = document.querySelectorAll(".sidebar .side-menu li a");
    sideLinks.forEach((link) => {
      const path = link.getAttribute("href");
      if (path === location.pathname) {
        link.parentElement.classList.add("active");
      } else {
        link.parentElement.classList.remove("active");
      }
    });
  }, [location]);

  const handleLogout = () => {
    onLogout();
  };

  return (
    <div className="sidebar">
      <Link to="/" className="logo">
        <div className="logo-name">
          <img src="logo.png" alt="Logo" />
        </div>
      </Link>

      <ul className="side-menu">
        <li>
          <Link to="/" className="nav-link">
            <i className="bx bx-data"></i>Dashboard
          </Link>
        </li>
        {user.type !== "superadmin" ? (
          <>
            <li>
              <Link to="/scan-room" className="nav-link">
                <i className="bx bx-spreadsheet"></i>Scan Room
              </Link>
            </li>
            <li>
              <Link to="/rfid" className="nav-link">
                <i className="bx bx-rfid"></i>RFID Menu
              </Link>
            </li>
            {user.type !== "admin" && (
              <li>
                <Link to="/request" className="nav-link">
                  <i className="bx bx-mail-send"></i>Requests
                </Link>
              </li>
            )}
            {user.type === "admin" && (
              <li>
                <Link to="/admin-menu" className="nav-link">
                  <i className="bx bxs-user-account"></i>Admin Menu
                </Link>
              </li>
            )}
          </>
        ) : (
          <>
            <li>
              <Link to="/approval" className="nav-link">
                <i className="bx bxs-edit"></i>Approval
              </Link>
            </li>
          </>
        )}
        <li>
          <Link to="/history" className="nav-link">
            <i className="bx bx-history"></i>History Transfer
          </Link>
        </li>
        <li>
          <Link to="/history-doc" className="nav-link">
            <i className="bx bx-history"></i>History Perubahan
          </Link>
        </li>
        <li>
          <Link to="/settings" className="nav-link">
            <i className="bx bx-cog"></i>Settings
          </Link>
        </li>
      </ul>

      <ul className="side-menu">
        <li>
          <a className="logout" onClick={handleLogout}>
            <i className="bx bx-log-out-circle" id="logoutButton"></i>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
